var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      [
        _c("h2", { staticClass: "mb-4" }, [
          _vm._v("Services Requiring Attention"),
        ]),
        _c("ajax-table", {
          ref: "services-requiring-attention",
          attrs: {
            "select-mode": "single",
            "should-enable-checkbox": _vm.preventSelectCheckBoxes,
            "table-definition": _vm.servicesDefinition,
            "no-local-sorting": true,
            emptyText: "You're all caught up!",
            hover: "",
          },
          on: { loaded: _vm.onLoad, "row-clicked": _vm.toggleFormFor },
          scopedSlots: _vm._u([
            {
              key: "cell(company.name)",
              fn: function (data) {
                return [
                  _c("span", { staticClass: "h6 btn-link" }, [
                    _vm._v(_vm._s(data.value)),
                  ]),
                ]
              },
            },
          ]),
        }),
        _c("item-requiring-attention-modal", {
          ref: "item-requiring-attention-modal",
          on: { completed: _vm.completed },
        }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }